import { Routes } from '@angular/router';
import { LoginPageComponent } from './modules/login/pages/login-page/login-page.component';
import { HomeComponent } from './modules/login/pages/home/home.component';
import { ValidateComponent } from './authentification/validate/validate.component';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/login/login.routing.module').then(
        (m) => m.LoginRoutingModule
      ),
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'validate',
    component: ValidateComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
