import {   NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
// import { SubSink } from 'src/app/core/subsink/subsink';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  providers: [   {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: DropdownComponent
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    NgFor,
    NgIf,
    FormsModule,ReactiveFormsModule, SlicePipe,NgClass
  ],
})
export class DropdownComponent implements  DropdownComponent{
  constructor(private _cdr: ChangeDetectorRef) {

  }
@Input() options: any[] = [];
@Input() bindLabel!: string;
@Input() bindValue!: string;
@Input() labelClass = '';
@Input() title = '';
@Input() multi = false ;
@Input() clearable = false;
@Input() disabled:boolean = false;
@Input() asteriskRequired:boolean = false;
@Input() message!: string;
@Input() placeholder:string = 'Select';
@Input() enableValidatorMessage = false;
@Input() labelShow:boolean = true;
@Input() addTag = false;
@Input() searchable = false;
@Input() validationError:ValidationErrors | null | undefined = null;
@Input() selectedValue:any;
@Output() changeEvent = new EventEmitter();
public value = new FormControl<any>(null);
// public subs = new SubSink();
onChanged: (value: string | null) => string = () => '';
onTouched: () => boolean = ()=> false;


// ngOnInit(): void {
//   this.subs.add( this.value
//        .valueChanges // subscribe to all changes
//        .subscribe(
//          (data: string | null) => {
//            if (data !== '') {
//              this.onTouched();
//            }
//            this.onChanged(data);
//          }
//        ));
//  }


 registerOnChange(fn: () => string) {
  this.onChanged = fn;
}
registerOnTouched(fn: () => boolean) {
  this.onTouched = fn;
}

writeValue(value: string): void {
  this.value.setValue(value);
  this._cdr.detectChanges();
}

onChange(event: any): void {
  this.changeEvent.emit(event);
}

// ngOnDestroy(): void {
//   this.subs.unsubscribe();
// }






}

