
<div class="scad-dropdown" [ngClass]="{'scad-dropdown--asterisk': asteriskRequired}">
    <label [class]="'scad-dropdown__title ' + labelClass" *ngIf="labelShow" >{{title}}</label>
    <div [ngClass]="{ 'scad-dropdown--disable': disabled }">
    <ng-select
    [(ngModel)]="selectedValue"
      [placeholder]="placeholder"
      [items]="options"
      [multiple]="multi"
      [closeOnSelect]="!multi"
      [searchable]="searchable"
      [bindLabel]="bindLabel"
      [formControl]="value"
      [bindValue]="bindValue"
      (change)="onChange($event)"
      [clearable]="clearable"
      [addTag]="addTag"
      addTagText="Add New"
    >
  
    <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      @for (item of items; track item; let index= $index) {
        @if (bindLabel && index ===0) {
          <div class="ng-value" >
            <div class="scad-dropdown__multi-selection">  {{ item[bindLabel] ?  item[bindLabel]: ''}}</div>
          </div>
        }
  
      }
  
  
  </ng-template>
      <ng-template ng-label-tmp let-item="item" >
        @if (multi) {
          <div class="scad-dropdown__multi-selection">  {{ item[bindLabel] }}</div>
        }@else {
         {{ item[bindLabel] }}
        }
  
  
      </ng-template>
  
      <ng-template ng-option-tmp let-item="item" let-item$="item$"  let-index="index"
      >
      @if (multi) {
        <input
        type="checkbox"
        [ngModel]="item$.selected"
      />
      }
  
        {{ item[bindLabel]}}
      </ng-template>
  
    </ng-select>
    @if (multi && value.value?.length > 0) {
      <div class="scad-dropdown__selected-num" >
        {{value.value?.length}}
      </div>
    }
  </div>
  </div>
  @if (message) {
  
    <span class="nam-modal__error">{{message}}</span>
  }@else {
    @if(validationError&&enableValidatorMessage) {
    @if(validationError['required']) {
    <div class="scad-dropdown__error">
      {{ "This field is Required"}}
    </div>
    }
  }} -->
  