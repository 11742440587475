import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { HomeService } from './home.service';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FunctionHelperService } from '../../../../core/helper/function.helper.service';
import { LoginService } from '../../service/login.service';
import { environment } from '../../../../../environments/environment';
import { NvmTabComponent } from '../../../nvm-tab/nvm-tab.component';
import { ModalComponent } from '../../../modal/modal.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    DropdownComponent,
    FormsModule,
    NgFor,
    NgIf,
    NvmTabComponent,
    ModalComponent,
    CommonModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  public fromPlaceholder: string = 'From';
  public toPlaceholder: string = 'To';
  public ticketIds: string = '';
  public sourceQuery: string = '';
  public convertedQuery: string = '';
  public fromDialect: string = '';
  public toDialect: string = '';
  public changeValue: string = '';
  public historyUuid: string = '';
  public historyStarredCompleteList: string | any;
  public historyUuidOpenAi: string = '';
  public isProfile: boolean = false;
  public openAiQuery: string = '';
  public isOpenAiResult: boolean = false;
  public isNextbutton: boolean = false;
  public historyItems: any[] = [];
  public starredItems: any[] = [];
  public currentTab: string = 'History';
  public isStarred: boolean = false;
  public showCopyTooltip: boolean = false;
  public showMigration: boolean = false;
  public showStarTooltip: boolean = false;
  public isStarHovered: boolean = false;
  public showStarredTooltip = false;
  public showOpenAIResult = true;
  public isCopyHovered: boolean = false;
  public showToggleContainer: boolean = false;
  public showHistory: boolean = true;
  public showToggleStarContainer: boolean = false;
  public copyOpenAi: any;
  public copyConvertQuery: any;
  public tabOptions: any[] = [
    { id: 0, name: 'History' },
    { id: 1, name: 'Add to Favourites' },
  ];


  tickeList: any = [
    { id: 'MYSQL', name: 'MYSQL' },
    { id: 'ORACLE', name: 'ORACLE' },
    { id: 'POSTGRES', name: 'POSTGRES' },
    { id: 'SNOWFLAKE', name: 'SNOWFLAKE' },
    { id: 'SQLITE', name: 'SQLITE' },
    { id: 'TABLEAU', name: 'TABLEAU' },
    { id: 'TSQL', name: 'TSQL' },
    { id: 'DATABRICKS', name: 'DATABRICKS' },
  ];
  filteredToOptions: any[] = [...this.tickeList];

  isCopied: boolean = false;
  showCopyButton = false;
  apiUrl = environment.baseUrl + environment.apiVersion;
  userEmail = 'abhishek.kj@beinex.com';
  fromSelectValue: any;
  toSelectValue: any;
  constructor(
    private http: HttpClient,
    private home: HomeService,
    private _toaster: ToastrService,
    private router: Router,
    private _helperService: FunctionHelperService,
    public login: LoginService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log(this._helperService.userData);
    this.getProfile();
    this.fetchHistoryList();
    this.fetchStarredList();
  }

  copyToClipboard() {
    const textarea = document.getElementById(
      'textarea-copy'
    ) as HTMLTextAreaElement;
    if (textarea) {
      navigator.clipboard
        .writeText(textarea.value)
        .then(() => {
          this.isCopied = true;
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        })
        .catch((err) => {});
    }
  }

  changeEvents(event?: any, dropdownType?: 'from' | 'to') {
    if (dropdownType === 'from') {
      this.fromDialect = event.id;
      this.fromPlaceholder = '';
      this.filteredToOptions = this.tickeList.filter(
        (item: any) => item.id !== this.fromDialect
      );
    } else if (dropdownType === 'to') {
      this.toDialect = event.id;
      this.toPlaceholder = '';
    } else {
      this.fromDialect = 'From';
      this.toDialect = 'to';
    }
  }

  convertQuery(type?: string) {
    const { sourceQuery, fromDialect, toDialect } = this;
    if (!sourceQuery) {
      return this._toaster.error('Please fill the fields');
    }

    if (!fromDialect || !toDialect) {
      return this._toaster.error('Please select the dropdown items');
    }

    const payload = {
      prompt: sourceQuery,
      from_query: fromDialect ? fromDialect : '',
      to_query: toDialect ? toDialect : '',
    };

    this.home.getConvert(payload).subscribe(
      (result: any) => {
        this.convertedQuery = result.data;
        this.copyConvertQuery = JSON.parse(JSON.stringify(result.data));
        this.showCopyButton = true;
        this.isOpenAiResult = false;
        this.showMigration = true;
        this.historyUuid = result.history_uuid;
        if (type != 'previous') {
          this.fetchHistoryList();
        }
      },

      (error) => console.error('Error converting query:', error)
    );

    return;
  }

  openAi() {
    const payload = {
      prompt: this.sourceQuery,
      from_query: this.fromDialect,
      to_query: this.toDialect,
    };

    this.home.getOpen(payload).subscribe(
      (result: any) => {
        let cleanedResult = result.data.replace(/\n\n/g, '');
        this.convertedQuery = cleanedResult;
        this.copyOpenAi = JSON.parse(JSON.stringify(cleanedResult));
        this.openAiQuery = result.data;
        this.showCopyButton = true;
        this.isOpenAiResult = true;
        this.historyUuidOpenAi = result.history_uuid;

        this.isStarred = false;

        this.fetchHistoryList();
      },
      (error) => console.error('Error converting query:', error)
    );
    this.showToggleContainer = true;
  }

  handleResult(result: any) {
    let cleanedResult = result.data.replace(/\n\n/g, '');
    this.convertedQuery = cleanedResult;
    this.showCopyButton = true;
  }

  handleError(error: any) {
    console.error('Error converting query:', error);
  }

  logout() {
    this.login.logout().subscribe((res: any) => {
      this._helperService.deleteCookie().then(() => {
        this.router.navigate(['/login']);
      });
    });
  }

 
  onTabChange(event: any) {
    console.log('Tab changed:', event);
    this.currentTab = event.name;

    if (event.id === 0) {
      this.fetchHistoryList();
    } else if (event.id === 1) {
      this.fetchStarredList();
    }
  }

 

  fetchHistoryList() {
    const offset = 0;
    const limit = 9;
    this.home.getHistoryapi(offset, limit).subscribe(
      (response: any) => {
        if (response && response.results) {
          this.historyItems = response.results.map((item: any) => ({
            from_query: item.from_query,
            query: item.query,
            to_query: item.to_query,
            uuid: item.uuid,
            starred: item.starred,
          }));
        }
        console.log('lll', this.historyItems);
      },
      (error) => {
        console.error('Error fetching history:', error);
      }
    );
  }
  fetchStarredList() {
    this.home.getStarredapi().subscribe(
      (response: any) => {
        if (response && response.results) {
          this.starredItems = response.results.map((item: any) => ({
            from_query: item.from_query,
            query: item.query,
            to_query: item.to_query,
            uuid: item.uuid,
            starred: item.starred,
          }));
        }
        console.log('lll', this.starredItems);
      },
      (error) => {
        console.error('Error fetching history:', error);
      }
    );
  }
  getUserProfilePic() {
    return `${this.apiUrl}user-profile-picture/list?email=${this._helperService.userData.email}`;
    // return `${
    //   this.apiUrl
    // }user-profile-picture/list?email=${'abhishek.kj@beinex.com'}`;
  }
  getProfile() {
    this.isProfile = true;
    this.home.getProfile(this._helperService.userData.email).subscribe(
      (result: any) => {},
      (error) => {
        if (error.error === 'Error fetching photo') {
          this.isProfile = false;
        }
      }
    );
  }
  toggleStar(uuidClick: string, fromStar: string) {
    console.log('uuisclo', uuidClick);
    this.showToggleStarContainer = !this.showToggleStarContainer;
    // if (!uuidClick){
    // this.isStarred = !this.isStarred;
    //     this.showStarredTooltip = true;
    // }//need code
    if (fromStar === 'textbox') {
      const historyStarBox = this.historyItems.find(
        (item: any) => item.uuid === this.historyUuid
      );

      this.isStarred = !historyStarBox?.starred;
    } else {
      const historyStarBox = this.starredItems.find(
        (item: any) => item.uuid === this.historyUuidOpenAi
      );
      this.isStarred = !historyStarBox?.starred;
    }
    this.showStarredTooltip = true;

    setTimeout(() => {
      this.showStarredTooltip = false;
    }, 2000);
    const formData = new FormData();
    
    const uuid = uuidClick ? uuidClick : this.historyItems[0].uuid;

    console.log('uuid', uuid);

    const value = this.showToggleStarContainer ? '1' : '0';
    // const value = this.isStarred ? '1' : '0';
    this.cdr.detectChanges();
    formData?.append('starred', value);
    this.home.getStarredHistory(formData, uuid).subscribe(
      (response: any) => {
        if (response && response.results) {
          this.starredItems = response.results.map((item: any) => ({
            starred: item.starred,
          }));
        }
        this.fetchHistoryList();
        this.fetchStarredList();
        // this.isStarred = false;

        console.log('starredItems', this.starredItems);
      },
      (error) => {
        console.error('Error fetching history:', error);
      }
    );
  }


  showPreviousResult(result: string) {
    this.showOpenAIResult = !this.showOpenAIResult;
    console.log('Toggle state', result);

    if (result === 'Previous') {
      this.historyStarredCompleteList = this.historyItems.find(
        (item: any) => item.uuid === this.historyUuid
      );
      console.log(
        'historyStarredCompleteList',
        this.historyStarredCompleteList,
        this.historyUuid
      );
      this.isStarred = this.historyStarredCompleteList?.starred;

      
      this.convertedQuery = this.copyConvertQuery;
    } else {
      this.historyStarredCompleteList = this.starredItems.find(
        (item: any) => item.uuid === this.historyUuidOpenAi
      );
      this.isStarred = this.historyStarredCompleteList?.starred;

      this.convertedQuery = this.copyOpenAi;
      // this.isStarred = false;
    }
    console.log('copyConvertQuery', this.copyConvertQuery);
    console.log('this.copyOpenAi;', this.copyOpenAi);
  }

  clearItems() {
    if (this.currentTab === 'History') {
      this.clearHistory();
    } else if (this.currentTab === 'Add to Favourites') {
      this.clearStarred();
    }
  }
  clearHistory() {
    this.home.getHistoryClearall().subscribe(
      (response) => {
        this.historyItems = [];
        console.log('History cleared:', response);
      },
      (error) => {
        console.error('Error clearing history:', error);
      }
    );
  }
  clearStarred() {
    this.home.getStarredClearall().subscribe(
      (response) => {
        this.starredItems = [];
        console.log('History cleared:', response);
      },
      (error) => {
        console.error('Error clearing history:', error);
      }
    );
  }
  deletItemHistory(uuid: any) {
    this.home.getHistoryDelet(uuid).subscribe(
      (response) => {
        console.log('History cleared:', response);
        this.fetchHistoryList();
      },
      (error) => {
        console.error('Error clearing history:', error);
      }
    );
  }
  deletItemStarred(uuid: any) {
    this.home.getStarredDelet(uuid).subscribe(
      (response) => {
        console.log('History cleared:', response);
        this.fetchStarredList();
      },
      (error) => {
        console.error('Error clearing history:', error);
      }
    );
  }

  newMigration() {
    this.fromDialect = '';
    this.toDialect = '';
    this.changeValue = '';
    this.fromSelectValue = 'From';
    this.toSelectValue = 'To';
    this.sourceQuery = '';
    this.convertedQuery = '';
    this.isCopied = false;
    this.showCopyButton = false;
    this.isOpenAiResult = false;
    this.isNextbutton = false;
    this.redirectTo('/home');
  }
  redirectTo(url: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
}
