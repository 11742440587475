import { CommonModule, NgIf, } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [NgIf,CommonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
    @Input() label: string = '';
    @Input() label1: string = '';
    @Input() desc: string = '';
    @Input() showIcon: boolean = true;
    @Input() showModal : boolean = true;
    @Input() rightImageSrc: string = '';
    @Input() copyImage: string = '';
    @Input() uuid: string = '';
     public tooltipText: string = 'Star'; 
     public tooltipVisible: boolean = false; 
     public isStarred: boolean = false;
     public isCopyHovered: boolean = false;
     public isCopied: boolean = false;
  @Output() toggleStars: EventEmitter<void> = new EventEmitter<void>(); 
  @Output() toggleStarClick: EventEmitter<void> = new EventEmitter<void>(); 
  @Output() clearItems: EventEmitter<void> = new EventEmitter<void>(); 
  
  isStarHovered: boolean = false;
  showStarredTooltip: boolean = false;

  copyToClipboard() {
    const textarea = document.createElement('textarea');
    textarea.value = this.desc; 
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000); 
  }
  closeModal(uuid:any){
    this.clearItems.emit(uuid);
   
   
  }

  showStarTooltip() {
    this.tooltipVisible = true;
    this.tooltipText = this.isStarred ? 'Added to Favourites' : 'Add to Favourites';
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  toggleStar() {
    this.toggleStarClick.emit();

    this.isStarred = !this.isStarred;
    this.tooltipText = this.isStarred ? 'Added to Favourites' : 'Add to Favourites'; 
  }
}
