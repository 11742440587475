import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-nvm-tab',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nvm-tab.component.html',
  styleUrl: './nvm-tab.component.scss'
})
export class NvmTabComponent {

  @ViewChildren('tabs') tabs!: QueryList<ElementRef>;
  @Input() isArabic = false;
  @Input() tabCountShow = false;
  @Input() overflowVisible: boolean = false;
  @Input() marginVisible: boolean = false;
  @Input() headingVisible: boolean = false;
  @Input() tabCount:Record<string , any>[] = [];
  @Input() tabValue:Record<string , any>[] = [];
  @Input() activeIndex: null | number = 0;
  @Output() tabClickEvent = new EventEmitter();
  @Output() searchListEvent:EventEmitter<string> = new EventEmitter<string>();
  public searchText:string='';
  // @Input() data!: TabDetails[];



  next() {
    if (this.activeIndex !== null && this.tabValue.length -1 > this.activeIndex ) {
      this.activeIndex++;
      this.alignTab(this.activeIndex);
      this.tabClickEvent.emit({data: this.tabValue[this.activeIndex], index: this.activeIndex});
    }
  }
  prev() {
    if (this.activeIndex !== null &&  this.activeIndex > 0) {
      this.activeIndex--;
      this.alignTab(this.activeIndex);
      this.tabClickEvent.emit({data: this.tabValue[this.activeIndex], index: this.activeIndex});
    }
  }
  search(env: any) {
    this.searchText = (env?.target?.value)?.trim();
    this.searchListEvent.emit(this.searchText);
  }

  // tabClick(index : number) {
  //   this.activeIndex = index;
  //   this.alignTab(this.activeIndex);
  //   this.tabClickEvent.emit({data: this.tabValue[index], index: index});
    
  // }
  tabClick(index: number) {
     this.activeIndex = index;
    // this.alignTab(this.activeIndex);
    const selectedTab = this.tabValue[index]; 
  
    this.tabClickEvent.emit({ id: selectedTab['id'], name: selectedTab['name'] });
  }
  
  alignTab(index: number) {
    const tabs = this.tabs.toArray();
    tabs[index].nativeElement.scrollIntoView({ behavior: 'instant',block: 'nearest', inline: 'start'});
  }
  
  getTabImage(id: number, isActive: boolean): string {
    if (isActive) {
        switch (id) {
            case 0: // History
                return '../../../assets/images/Icon.svg'; // Active image version
            case 1: // Starred
                return '../../../assets/images/path69 (3).svg'; // Active image version
            default:
                return '../../../assets/images/default-icon-active.svg'; // Active default image
        }
    } else {
        switch (id) {
            case 0: // History
                return '../../../assets/images/Icon 3.svg';
            case 1: // Starred
                return '../../../assets/images/path69 (1).svg';
            default:
                return '../../../assets/images/default-icon.svg';
        }
    }
}
}
