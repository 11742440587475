<div class="syn-tab">
    <div class="syn-tab__wrapper" [ngClass]="{ 'line-show': overflowVisible }">
      @for (item of tabValue; track item; let index = $index) {
        <div #tabs class="syn-tab__text"
             [ngClass]="{'syn-tab__text--active': index==activeIndex,'marginleftright': marginVisible}"
             (click)="tabClick(index)">
             <img [src]="getTabImage(item['id'], index === activeIndex)" 
     [alt]="item['name'] + ' icon'" 
     class="syn-tab__icon"   
     [ngClass]="{'syn-tab__text--active': index === activeIndex, 'marginleftright': marginVisible}">

                    <span>{{item['name']}}</span>
          @if (tabCountShow) {
            <span class="syn-tab__count" [ngClass]="{'syn-tab__active-count': index === activeIndex}">
              {{tabCount[item?.['id']]}}
            </span>
          }
        </div>
      }
    </div>
  </div>