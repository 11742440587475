import { Injectable } from '@angular/core';
import { HttpService } from '../../../../core/services/common/HttpService';
import { ApiEndPoint } from '../../../../core/constants/end-points';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor( private _http:HttpService) { }
  getConvert(payload:any) {
    return this._http.post( `${ApiEndPoint.apiConvert}`,payload);
  }
  getOpen(payload:any){
    return this._http.post( `${ApiEndPoint.convertApi}`,payload);
  }
  getProfile(userEmail:any){
    return this._http.get(`${ApiEndPoint.profileChange}?email=${userEmail}`);
  }
  getHistoryapi( offset: number,limit: number,){
    return this._http.get(`${ApiEndPoint.historyApi}?offset=${offset}&limit=${limit}`);
  }
  getStarredapi (offset: number = 0, limit: number = 9){
    return this._http.get(`${ApiEndPoint.starredApi}?starred=1&offset=${offset}&limit=${limit}`);
  }
  getStarredHistory (data:FormData,uuid:string){
    return this._http.put(`${ApiEndPoint.historyStar}${uuid}`,data);
  }
getHistoryClearall(){
  return this._http.delete(`${ApiEndPoint.historyClearAll}`);

}
getStarredClearall(){
  return this._http.delete(`${ApiEndPoint.starredClearAll}?starred=1`);
}
getHistoryDelet(uuid:string){
  return this._http.delete(`${ApiEndPoint.historyDelet}${uuid}`);

}
getStarredDelet(uuid:string){
  return this._http.delete(`${ApiEndPoint.historyDelet}${uuid}?starred=1`);

}

}
