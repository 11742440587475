import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FunctionHelperService } from '../../core/helper/function.helper.service';
import { loginUrls, returnUrl, userRole } from '../../core/constants/constants';

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
})

export class ValidateComponent {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _helper: FunctionHelperService,
    private router: Router,) {
     }

  ngOnInit() {
    const key = this._activatedRoute.snapshot.queryParamMap.get('key');
    const username = this._activatedRoute.snapshot.queryParamMap.get('user_name');
    const email = this._activatedRoute.snapshot.queryParamMap.get('email');
    const first_name = this._activatedRoute.snapshot.queryParamMap.get('first_name');
    const last_name = this._activatedRoute.snapshot.queryParamMap.get('last_name');

    const data:any = {'key': key,'user_name': username,'email': email,'status': 200, 'first_name': first_name, 'last_name': last_name};
    this._helper.setUser(data);
    console.log("data",data)
    this.router.navigate(['/home']);
  }
}
