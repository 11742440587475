import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FunctionHelperService } from '../helper/function.helper.service';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements Permissions  {
 
  constructor(
    private _router: Router,
    private _helper: FunctionHelperService) { }
  query(permissionDesc: PermissionDescriptor): any {
  }
   
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this._helper.isLogged){
        return true;
      }else{
          this._router.navigateByUrl('login');
        return false;
      }
  }
}