export enum ApiEndPoint {
  apiConvert ='sql-glot/query-convert',
  convertApi ='open-ai/query-convert',
  profileChange ='user-profile-picture/list',
  historyApi ='syntax-migrator/history/list',
 starredApi= 'syntax-migrator/history/list',
 historyStar='history-starred/update/',
 historyClearAll ='history/clear-all',
starredClearAll ='/history/clear-all',
historyDelet ='history/delete/'

}
