<div class="migration-accelerator">
   <div class="migration-accelerator__header">
      <img src="/assets/images/Beinex_Logo 1.svg" alt="Beinex Logo" class="logo">
      <div class="migration-accelerator__h1">Beinex Migration Accelerator</div>
      <div class="migration-accelerator__img-div">
       
      <img  class="migration-accelerator__avatar"  [src]=" isProfile ? getUserProfilePic():'/assets/images/Avatar.svg'" alt="Beinex Logo" >
 <div class="logout-container">
  <span class="tooltip">Logout</span>
    <img (click)="logout()" src="/assets/images/logout 1.svg" alt="Logout" class="logout-icon">
   
  </div>      </div>

    </div>
        <div class="migration-accelerator__main">
      <div class="migration-accelerator__conversion-container">
        <div class="migration-accelerator__dropdown-container">
            <app-dropdown
            [labelShow]="false"
            [bindLabel]="'name'"
            [options]="tickeList"
            [selectedValue]="fromSelectValue"
            (changeEvent)="changeEvents($event, 'from')"
            [placeholder]="fromPlaceholder? fromPlaceholder:''"
          ></app-dropdown>
          <span class="migration-accelerator__arrow">⇄</span>
          <app-dropdown
            [labelShow]="false"
            [bindLabel]="'name'"
            [options]="filteredToOptions"
            [selectedValue]="toSelectValue"
            (changeEvent)="changeEvents($event, 'to')"
            [placeholder]="toPlaceholder? toPlaceholder:''"
          ></app-dropdown>
        </div>
        @if (showMigration){
          <div class="migration-accelerator__new-migration">
            <img class="migration-accelerator__mig-img" (click)="newMigration()" src="../../../../../assets/images/add.svg" alt="">
            <span class="migration-accelerator__mig-text" >New Migration</span>
          </div>
        }
        <div class="migration-accelerator__code-editors">
            
            <textarea [(ngModel)]="sourceQuery"></textarea>

  <div class="migration-accelerator__output-container">
    <textarea id="textarea-copy" [(ngModel)]="convertedQuery" readonly></textarea>
    
  
    @if (showCopyButton) {
      <div class="migration-accelerator__copy-button-container">
        <!-- Star Section -->
        <div class="migration-accelerator__star-wrapper">
          <button class="migration-accelerator__star-button"
                  (click)="toggleStar(historyStarredCompleteList?.uuid,'textbox')"
                  (mouseenter)="isStarHovered = true"
                  (mouseleave)="isStarHovered = false"
                  >
                  <img [src]="isStarred ? '../../../../../assets/images/path69 (3).svg' : '../../../../../assets/images/path69 (1).svg'" 
                  [ngClass]="{'starred': isStarred}" 
                  alt="Star" />          </button>
          <span class="migration-accelerator__tooltip migration-accelerator__tooltip--hover" *ngIf="isStarHovered">Star</span>
          @if (showStarredTooltip) {
            <span class="migration-accelerator__tooltip migration-accelerator__tooltip--click">Add to Favourites</span>
          }
        </div>
        
        <!-- Copy Section -->
        <div class="migration-accelerator__copy-wrapper">
          <button class="migration-accelerator__copy-button"
                  (click)="copyToClipboard()"
                  (mouseenter)="isCopyHovered = true"
                  (mouseleave)="isCopyHovered = false">
            <img src="../../../../../assets/images/Group 1000002342.svg" alt="Copy" />
          </button>
          <span class="migration-accelerator__tooltip migration-accelerator__tooltip--hover" *ngIf="isCopyHovered">Copy</span>
          @if (isCopied) {
            <span class="migration-accelerator__tooltip migration-accelerator__tooltip--click">Copied!</span>
          }
        </div>
      </div>
    }
    
  </div>
        </div>
     
      </div>
      <div class="migration-accelerator__action-container">
        @if(!convertedQuery){
        <button class="migration-accelerator__convert" (click)=" convertQuery()">Convert </button>
        } @else {
          @if (!isOpenAiResult && !showToggleContainer) {
            <p>Not satisfied with the Result?</p>
          }
          <button  *ngIf="convertedQuery && !showToggleContainer" class="migration-accelerator__convert" (click)="openAi()">
            
          
          Convert using Open AI
          </button>


        }
        
        <div class="migration-accelerator__toggle-container" *ngIf="showToggleContainer" >
          <div class="migration-accelerator__button"
          [ngClass]="{'active' : !showOpenAIResult }"
          (click)="showPreviousResult('Previous')">
            Previous Result      </div>
          <div class="migration-accelerator__button"
          [ngClass]="{'active' : showOpenAIResult }"

          (click)="showPreviousResult('Openai')">
            Result with Open AI
      </div>
        </div>
      </div>
      
    </div>
   
  
  </div>
  <div class="migration-accelerator__second-part" >
    <app-nvm-tab
    (tabClickEvent)="onTabChange($event)"
    [headingVisible]="true"
    [marginVisible]="true"
    [overflowVisible]="false"
    [tabValue]="tabOptions"
    class="syn-tab__tab"
  ></app-nvm-tab>
  <div class="migration-accelerator__clear" *ngIf="(historyItems.length > 0 && currentTab === 'History') || (starredItems.length > 0 && currentTab === 'Add to Favourites') "(click)="clearItems()">Clear All</div>


<div class="migration-accelerator__historyCard">
  <!-- History Tab -->
  <ng-container *ngIf="currentTab === 'History' && showHistory">
    <ng-container *ngIf="historyItems.length > 0; else showEmptyHistory">
      <app-modal
        *ngFor="let item of historyItems"
        [label]="item.from_query"
        [label1]="item.to_query"
        [showIcon]="true"
        [copyImage]="'../../../../../assets/images/Group 1000002342.svg'"
        [rightImageSrc]="item.starred ? '../../../../../assets/images/path69 (3).svg' : '../../../../../assets/images/path69 (1).svg'" 
        class="migration-accelerator__history-card"
        [desc]="item.query"
        [uuid]="item.uuid"
        (clearItems)="deletItemHistory($event)"
        (toggleStarClick)="toggleStar(item.uuid,'history')">
        
      </app-modal>
    </ng-container>

    <ng-template #showEmptyHistory>
      <div class="migration-accelerator__empty-state">
        <img src="../../../../../assets/images/empty-folder 1.svg" alt="No History" class="migration-accelerator__empty-image">
        <p class="migration-accelerator__empty-text">No Data Found</p>
      </div>    </ng-template>
  </ng-container>

  <!-- Starred Tab -->
  <ng-container *ngIf="currentTab === 'Add to Favourites' && showHistory">
    <ng-container *ngIf="starredItems.length > 0; else showEmptyStarred">
      <app-modal
        *ngFor="let item of starredItems"
        [label]="item.from_query"
        [label1]="item.to_query"
        [showIcon]="false"
        class="migration-accelerator__history-card"
        [desc]="item.query"
        [uuid]="item.uuid"

        (clearItems)="deletItemStarred($event)"
        >
      </app-modal>
    </ng-container>

    <ng-template #showEmptyStarred>
      <div class="migration-accelerator__empty-state">
        <img src="../../../../../assets/images/empty-folder 1.svg" alt="No History" class="migration-accelerator__empty-image">
        <p class="migration-accelerator__empty-text">No Data Found</p>
      </div>
    </ng-template>
  </ng-container>
</div>

</div>
