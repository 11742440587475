
  <div *ngIf="showModal" class="app-modal">
    <div class="syn-close__tooltip-wrapper">
      <img
        (click)="closeModal(uuid)"
        class="app-modal__close"
        src="../../../assets/images/Group (4).svg"
        alt=""
      />
      <span class="syn-close__tooltip-text">Delet</span>
    </div>
    <div class="app-modal__main">
      <div class="app-modal__main-div">
        {{ label }}
       <img class="app-modal__img" src="../../../assets/images/Vector (12).svg" alt="">
       {{label1}}
      </div>
      <div>
        @if(showIcon)
{
  <div class="app-modal__main-img">
    <div class="migration-accelerator__copy-wrapper">
      <button class="migration-accelerator__copy-button"
              (click)="copyToClipboard()"
              (mouseenter)="isCopyHovered = true"
              (mouseleave)="isCopyHovered = false">
        <img [src]="copyImage" alt="Copy" />
      </button>
      <span class="migration-accelerator__tooltip migration-accelerator__tooltip--hover" *ngIf="isCopyHovered">Copy</span>
      <span class="migration-accelerator__tooltip migration-accelerator__tooltip--click" *ngIf="isCopied">Copied!</span>
    </div>

<div>
  <div class="tooltip-wrapper">
    <img
      [src]="rightImageSrc"
      (mouseenter)="showStarTooltip()"
      (mouseleave)="hideTooltip()"
      (click)="toggleStar()"
      alt="Right Image"
    />
    <span class="tooltip-text" *ngIf="tooltipVisible">{{ tooltipText }}</span>
  </div>
</div>   
  </div>


}      </div>
    </div>
    <div class="app-modal__main app-modal__main--desc">{{desc}}</div>
  </div>
  